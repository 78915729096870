.contact-form-container {
	background-color: #000;
	color: #fff;
	padding: 3rem 1rem;
	text-align: center;
	z-index: 2;
}

.contact-form-container h2 {
	font-size: 2rem;
	font-weight: bold;
	margin-bottom: 1rem;
}

.contact-description {
	margin-bottom: 2rem;
}

.contact-form {
	max-width: 500px;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	gap: 1rem;
	flex-wrap: nowrap;
	align-content: center;
	justify-content: space-around;
	align-items: center;
}

.contact-form textarea,
.contact-form input {
	width: 100%;
	padding: 0.75rem;
	background-color: transparent;
	border: 1px solid #444;

	color: #fff;
}

.contact-form textarea::placeholder,
.contact-form input::placeholder {
	color: #666;
}

.contact-form textarea {
	resize: vertical;
	min-height: 100px;
}

.submit-button {
	width: 100%;
	padding: 0.75rem;
	background-color: transparent;
	border: 1px solid #fff;
	color: #fff;
	cursor: pointer;
	font-weight: bold;
	transition: background-color 0.3s, color 0.3s;
}

.submit-button:hover {
	background-color: #fff;
	color: #000;
	border-radius: 0px;
}

.submit-message {
	margin-top: 1rem;
	font-weight: bold;
}

.submit-button:disabled {
	opacity: 0.5;
	cursor: not-allowed;
}
