.header {
  background: rgba(0, 0, 0, 0.75);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  transition: background-color 0.3s ease;
}

.header:hover {
  background: rgb(0, 0, 0);
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.mc-logo {
  width: 64px;
}

.navigation ul {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.navigation li {
  margin-left: 2rem;
}

.navigation button {
  background: none;
  border: none;
  cursor: pointer;
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  transition: color 0.3s ease;
  padding: 0;
}

.navigation button:hover {
  color: #00679f;
}

.menu-toggle {
  display: none;
}

@media (max-width: 768px) {
  .header-content {
    flex-wrap: wrap;
  }

  .menu-toggle {
    display: block;
    background: none;
    border: none;
    cursor: pointer;
  }

  .bar {
    display: block;
    width: 25px;
    height: 3px;
    margin: 5px auto;
    transition: all 0.3s ease-in-out;
    background-color: #ffffff;
  }

  .navigation {
    flex-basis: 100%;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
  }

  .navigation.open {
    max-height: 300px;
  }

  .navigation ul {
    flex-direction: column;
    padding-top: 1rem;
  }

  .navigation li {
    margin: 0 0 1rem 0;
  }

  /* Hamburger menu animation */
  .menu-toggle.active .bar:nth-child(2) {
    opacity: 0;
  }

  .menu-toggle.active .bar:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
  }

  .menu-toggle.active .bar:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }
}
