.footer {
	background-color: #000;
	color: #fff;
	padding: 2rem 0;
}

.footer-content {
	display: flex;
	justify-content: space-around;
	max-width: 1200px;
	margin: 0 auto;
	padding: 0 1rem;
	gap: 5%;
	flex-direction: row;
	flex-wrap: nowrap;
	align-content: space-between;
	align-items: center;
}

.footer-logo img {
	width: 256px; /* Adjust as needed */
}

.footer-info {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	width: 75%;
	gap: 10px;
}

.footer-section {
	flex: 1;
	min-width: 200px;
	margin-bottom: 1rem;
}

.footer-section h3 {
	color: #00679f;
	margin-bottom: 1rem;
}

.footer-section p {
	margin-bottom: 0.5rem;
	font-size: 0.9rem;
}

.social-icons {
	display: flex;
	gap: 1rem;
	flex-direction: column;
	flex-wrap: nowrap;
	align-items: center;
}

.social-icons a {
	width: 30px;
	height: 30px;
	background-size: contain;
	background-repeat: no-repeat;
	display: inline-block;
}

.linkedin-icon {
	background-image: url("../images/Iconlinkedit.png");
}

.twitter-icon {
	background-image: url("../images/Twittertwitter.png");
}

.footer-bottom {
	text-align: center;
	margin-top: 2rem;
	padding-top: 1rem;
	border-top: 1px solid #333;
}

a:link {
	color: white;
  text-decoration: none;
}

a:visited {
	color: inherit;
}

a:hover {
  font-weight: bold;
  color: inherit;
}

a:active {
	color: inherit;
}

@media (max-width: 768px) {
	.footer-content {
		flex-direction: column;
	}

	.footer-info {
		width: 100%;
		margin-top: 2rem;
	}

	.footer-section {
		min-width: 100%;
	}
}
