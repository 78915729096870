.hero {
  position: relative;
  height: 100vh;
  display: flex;
  align-items: center;
  overflow: hidden;
  justify-content: space-evenly;
  align-content: space-between;
  flex-direction: column;
}

.hero-content {
  position: relative;
  z-index: 2;
  max-width: 600px;
  color: white;
  padding: 20px;
  text-align: center;
}

.hero h1 {
  font-size: 48px;
  font-weight: bold;
  margin-bottom: 10px;
}

.hero h2 {
  font-size: 24px;
  font-weight: normal;
  margin-bottom: 20px;
}

.hero p {
  font-size: 16px;
  margin-bottom: 30px;
}

.cta-button {
  background-color: transparent;
  border: 2px solid white;
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.cta-button:hover {
  background-color: white;
  color: black;
}

.hero-image-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.hero-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

/* Add a dark overlay to improve text readability */
.hero-image-container::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.6376925770308124) 15%,
    rgba(0, 0, 0, 0) 100%
  );
  pointer-events: none;
}

@media (max-width: 768px) {
  .hero-content {
    margin: 0 20px;
  }

  .hero h1 {
    font-size: 36px;
  }

  .hero h2 {
    font-size: 20px;
  }
}