.values {
  background-color: #000;
  color: #fff;
  padding: 50px 0;
  text-align: center;
  overflow: hidden;
}

.values h2 {
  font-size: 32px;
  margin-bottom: 30px;
}

.value-card {
  background-color: #33333371;
  color: rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  padding: 20px;
  margin: 0 10px;
  transition: all 0.3s ease;
  height: 300px;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
}

.value-card.hovered {
  background-color: #33333371;
  color: rgba(255, 255, 255, 0.85);
}

.value-card.active {
  background-color: rgba(255, 255, 255, 1);
  transform: scale(1.05);
  color: black;
}

.value-card h3 {
  font-size: 20px;
  margin-bottom: 10px;
}

.value-card p {
  font-size: 14px;
  line-height: 1.4;
}

.slick-slide {
  transition: transform 0.3s;
}

.slick-center {
  transform: scale(1.05);
}

.slick-dots li button:before {
  color: #fff;
}

.slick-prev:before,
.slick-next:before {
  color: #fff;
}

@media (max-width: 768px) {
  .value-card {
    height: 250px;
  }
}
